<template>
  <div class="w-full max-h-screen bg-background h-full">
    <page-header backTo="staff" title="Edit Staff">
      <is-authorized :permissions="['delete:staff']">
        <button
            @click="deleteStaff()"
            type="button"
            class="ml-4 flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        >
          <svg
              class="stroke-current h-5 w-5 mr-2"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
          >
            <path
                d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
            />
          </svg>
          <span>Delete</span>
        </button>
      </is-authorized>
    </page-header>
    <main class="p-6 pb-20 bg-background flex-1 overflow-y-scroll h-full">
      <div v-if="staff" class="w-full bg-white p-5 rounded shadow-md">
        <h2 class="text-3xl mb-4">
          <span v-if="staff.first_name">{{ staff.first_name + " " }}</span>
          <!-- <span v-if="staff.middle_name">{{ staff.middle_name + " " }}</span> -->
          <span v-if="staff.last_name">{{ staff.last_name }}</span>
          <button
              @click="openEditPanel"
              class="ml-4 text-secondary bg-white p-1 rounded border border-transparent hover:border-secondary active:border-secondary active:shadow-inner active:bg-gray-200 transition duration-100"
          >
            <svg
                class="stroke-current h-6 w-6"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
            >
              <path d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"/>
            </svg>
          </button>
        </h2>

        <div>
          <div v-if="staff" class="flex items-center py-1">
            <svg
                class="text-orange-700 stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
            >
              <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"/>
              <circle cx="12" cy="7" r="4"/>
            </svg>
            <p
                v-if="staff.job_title"
                class="bg-orange-200 text-orange-700 rounded-lg py-1 px-2"
            >
              {{ staff.job_title }}
            </p>
            <p class="rounded-lg px-2 py-1">
              <span v-if="staff.first_name">{{ staff.first_name + " " }}</span>
              <!-- <span v-if="staff.middle_name">{{ staff.middle_name + " " }}</span> -->
              <span v-if="staff.last_name">{{ staff.last_name }}</span>
            </p>
          </div>
          <div v-if="staff.email" class="flex items-center py-1">
            <svg
                class="text-orange-700 stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
            >
              <path
                  d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
              />
              <path d="M22 6l-10 7L2 6"/>
            </svg>
            <a
                class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                :href="'mailto:' + staff.email"
            >{{ staff.email }}</a
            >
          </div>
          <div
              v-if="staff.mobile || staff.telephone"
              class="flex items-center py-1"
          >
            <svg
                class="text-orange-700 stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
            >
              <path
                  d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z"
              />
            </svg>
            <a
                v-if="staff.mobile"
                class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                :href="'tel:' + staff.mobile"
            >{{ staff.mobile }}</a
            >
            <a
                v-if="staff.telephone"
                class="text-blue-800 hover:bg-blue-100 rounded-lg px-2 py-1"
                :href="'tel:' + staff.telephone"
            >{{ staff.telephone }}</a
            >
          </div>
          <div class="flex items-center py-1">
            <svg
                class="text-orange-700 stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
            >
              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z"/>
              <circle cx="12" cy="10" r="3"/>
            </svg>
            <p class="rounded-lg px-2 py-1">{{ staffAddress }}</p>
          </div>
        </div>
      </div>

      <div v-if="staff" class="w-full bg-white p-5 rounded shadow-md mt-6">
        <div class="w-full mb-6">
          <div class="flex -mx-2 mr-2">
            <button
                @click="currentTab = 'info'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'info',
                'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                  currentTab != 'info',
              }"
            >
              <span>Information</span>
            </button>
            <button
                @click="currentTab = 'identification'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'identification',
                'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                  currentTab != 'identification',
              }"
            >
              <span>Identification</span>
            </button>
            <button
                @click="currentTab = 'emergency'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'emergency',
                'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                  currentTab != 'emergency',
              }"
            >
              <span>Emergency</span>
            </button>
            <button
                @click="currentTab = 'docs'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                'bg-orange-300 text-orange-900 border-orange-300':
                  currentTab == 'docs',
                'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200':
                  currentTab != 'docs',
              }"
            >
              <span>Documents</span>
            </button>

            <button
                @click="currentTab = 'signature'"
                class="px-4 py-2 rounded mx-2 transition-colors duration-300 border font-semibold"
                :class="{
                'bg-orange-300 text-orange-900 border-orange-300': currentTab == 'signature',
                'text-gray-900 bg-gray-100 border-gray-400 hover:bg-gray-200': currentTab != 'signature',
              }"
            >
              <span>Signature</span>
            </button>

          </div>
        </div>
        <div v-if="currentTab == 'info'">
          <StaffInformation :staff="staff" @complete="refreshStaff"/>
        </div>
        <div v-if="currentTab == 'identification'">
          <StaffIdentification :staff="staff" @complete="refreshStaff"/>
        </div>
        <div v-if="currentTab == 'emergency'">
          <StaffEmergency
              :emergencies="staff.emergency"
              @complete="refreshStaff"
          />
        </div>
        <div v-if="currentTab == 'docs'">
          <staff-documents :staff="staff" @complete="refreshStaff"/>
        </div>

        <div v-if="currentTab == 'signature'">
          <staff-signature :staff="staff" :visible="currentTab === 'signature'"/>
        </div>


        <div v-if="error">
          <div
              class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
          >
            <p>
              Couldn't load staff information:
              {{ error.statusText }}
            </p>
          </div>
        </div>

        <div>
          <div v-if="viewState == 'Error'">
            <div
                class="bg-red-200 border border-red-500 rounded-lg py-4 px-5 text-red-900"
            >
              <p>
                Something went wrong loading your enquiry, please try again. If
                the problem persists, please contact your support team.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
          v-else
          class="bg-white rounded shadow-md flex items-center justify-center py-20"
      >
        <spinner :color="'text-black'" :size="10"/>
      </div>
    </main>

    <portal to="overlay-outlet">
      <panel
          :showing="editDetailsPanelOpen"
          @close="editDetailsPanelOpen = false"
          title="Edit Staff Details"
      >
        <edit-staff-details
            :staffDetails="staffDetails"
            @complete="closeEditPanel"
        />
      </panel>
    </portal>

    <portal to="overlay-outlet">
      <confirm-alert
          :showingConfirm="confirmAlert.showing"
          :message="confirmAlert.message"
          @complete="confirmAlert.resultFunction"
      />
    </portal>

    <transition name="slide-bottom">
      <view-attachment
          v-if="viewingAttachmentOpen"
          :attachment="viewingAttachment"
          @close="viewingAttachmentOpen = false"
      />
    </transition>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const Panel = () => import("@/components/Panel.vue");
const Spinner = () => import("@/components/Spinner.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const Notification = () => import("@/components/Notification.vue");
const EditStaffDetails = () =>
    import("@/components/Staff/Edit/EditStaffDetails.vue");

const StaffInformation = () =>
    import("@/components/Staff/Edit/Information.vue");
const StaffIdentification = () =>
    import("@/components/Staff/Edit/Identification.vue");
const StaffEmergency = () => import("@/components/Staff/Emergency.vue");
const staffDocuments = () => import("@/components/Staff/Documents.vue");
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

const StaffSignature = () => import("@/components/Staff/Signature.vue");
export default {
  name: "EditStaff",
  components: {
    PageHeader,
    Panel,
    Spinner,
    ConfirmAlert,
    Notification,
    EditStaffDetails,
    StaffInformation,
    StaffIdentification,
    StaffEmergency,
    staffDocuments,
    IsAuthorized,
    StaffSignature
  },
  props: {
    staffId: String,
    isCreateResult: Boolean,
  },
  data() {
    return {
      viewState: "Idle",
      currentTab: "info",
      error: null,
      editDetailsPanelOpen: false,
      staff: null,
      staffDetails: {},
      viewingAttachment: {},
      viewingAttachmentOpen: false,
      staffAddress: "",
      isBusy: false,
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
    };
  },
  async mounted() {
    this.viewState = "Loading";

    try {
      this.staff = await this.StaffService.getStaffById(this.staffId);

      if (this.isCreateResult) {
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      title: "Staff Created",
                      close: onClose,
                    },
                  },
                  `Staff ${this.staff.first_name} ${this.staff.last_name}' was created`,
              );
            },
            {
              position: "top-right",
            },
        );
      }

      this.computeAddress();

      this.viewState = "Idle";
    } catch (err) {
      console.error(err);
      this.error = err;
      this.viewState = "Error";
    }
  },
  methods: {
    refreshStaff: async function () {
      this.staff = await this.StaffService.getStaffById(this.staffId);
    },

    openEditPanel() {
      this.staffDetails = {
        first_name: this.staff.first_name,
        middle_name: this.staff.middle_name,
        last_name: this.staff.last_name,
        job_title: this.staff.job_title,
        department: this.staff.department,
        mobile: this.staff.mobile,
        telephone: this.staff.telephone,
        email: this.staff.email,
        personal_email: this.staff.personal_email,
        address1: this.staff.address1,
        address2: this.staff.address2,
        address3: this.staff.address3,
        city: this.staff.city,
        county: this.staff.county,
        postcode: this.staff.postcode,
        staff_id: this.staff.staff_id,
        country: this.staff.country,
      };

      this.editDetailsPanelOpen = true;
    },

    handleDeleteStaff: async function (result) {
      if (result) {
        this.confirmAlert.showing = false;
        try {
          this.isBusy = true;
          await this.StaffService.deleteStaff(this.staff.staff_id);
          this.$router.push({
            name: "staff",
          });
          this.$breadstick.notify(
              ({h, onClose}) => {
                return h(
                    Notification,
                    {
                      props: {
                        title: "Success",
                        close: onClose,
                      },
                    },
                    `Staff '${this.staff.last_name} ${this.staff.first_name}' was deleted`,
                );
              },
              {
                position: "top-right",
              },
          );
        } catch (error) {
          console.error(error);
          this.$breadstick.notify(
              ({h, onClose}) => {
                return h(
                    Notification,
                    {
                      props: {
                        type: "danger",
                        title: "Error",
                        close: onClose,
                      },
                    },
                    "Error occurred while updating info, please try again later",
                );
              },
              {
                position: "top-right",
              },
          );
        }
        this.isBusy = false;
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },

    deleteStaff: function () {
      this.confirmAlert = {
        resultFunction: this.handleDeleteStaff,
        message: `Are you sure you wish to delete this staff?`,
        showing: true,
        data: this.staff,
      };
    },
    async closeEditPanel() {
      try {
        this.isBusy = true;
        this.staff.first_name = this.staffDetails.first_name;
        this.staff.last_name = this.staffDetails.last_name;
        this.staff.middle_name = this.staffDetails.middle_name;
        this.staff.job_title = this.staffDetails.job_title;
        this.staff.department = this.staffDetails.department;
        this.staff.email = this.staffDetails.email;
        this.staff.personal_email = this.staffDetails.personal_email;
        this.staff.mobile = this.staffDetails.mobile;
        this.staff.telephone = this.staffDetails.telephone;
        this.staff.address1 = this.staffDetails.address1;
        this.staff.address2 = this.staffDetails.address2;
        this.staff.address3 = this.staffDetails.address3;
        this.staff.city = this.staffDetails.city;
        this.staff.county = this.staffDetails.county;
        this.staff.postcode = this.staffDetails.postcode;
        this.staff.country = this.staffDetails.country;
        this.computeAddress();

        await this.StaffService.updateStaff(this.staff);

        await this.refreshStaff();

        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      title: "Update Successful",
                      close: onClose,
                    },
                  },
                  `Details for '${this.staff.first_name} ${this.staff.last_name}' were updated`,
              );
            },
            {
              position: "top-right",
            },
        );
        this.editDetailsPanelOpen = false;
      } catch (err) {
        console.error(err);
        this.$breadstick.notify(
            ({h, onClose}) => {
              return h(
                  Notification,
                  {
                    props: {
                      type: "danger",
                      title: "Error",
                      close: onClose,
                    },
                  },
                  "Error occurred while updating info, please try again later",
              );
            },
            {
              position: "top-right",
            },
        );
        this.editDetailsPanelOpen = false;
      }

      this.isBusy = false;
    },
    computeAddress: function () {
      let addr1 = this.staff.address1 ? `${this.staff.address1}, ` : "";
      let addr2 = this.staff.address2 ? `${this.staff.address2}, ` : "";
      let addr3 = this.staff.address3 ? `${this.staff.address3}, ` : "";
      let city = this.staff.city ? `${this.staff.city}, ` : "";
      let county = this.staff.county ? `${this.staff.county}, ` : "";
      let postcode = this.staff.postcode ? `${this.staff.postcode}, ` : "";
      let country = this.staff.country ? `${this.staff.country}` : "";

      this.staffAddress = `${addr1}${addr2}${addr3}${city}${county}${postcode}${country}`;
    },
  },
};
</script>
